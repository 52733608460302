import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import OtherReservationModal from './OtherReservationModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import PropTypes from 'prop-types';
import MassReservationModal from './MassReservationModal';
import { format } from 'date-fns';

const OtherReservationList = () => {
  const [otherReservations, setOtherReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [editingReservation, setEditingReservation] = useState(null);
  const [massReservationModalOpen, setMassReservationModalOpen] = useState(false);
  const [activeSeason, setActiveSeason] = useState(null);

  // Käytetään vain tätä funktiota
  const getReservationTypeInFinnish = (type) => {
    const types = {
      'reserved': 'Varattu paikka',
      'partner': 'Kumppani',
      'guest': 'Kausikortti Lippu.fi',
      'other': 'Muu'
    };
    return types[type] || type;
  };

  useEffect(() => {
    fetchActiveSeason();
    fetchReservations();
  }, []);

  const fetchReservations = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('other_reservations')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;

      setOtherReservations(data || []);
      setLoading(false);
    } catch {
      setError('Virhe varausten hakemisessa');
      setLoading(false);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const formattedSection = formData.section === 'A' ? 'A-katsomo' : 'D-katsomo';
      
      const dataToSubmit = {
        ...formData,
        section: formattedSection
      };

      const { error } = await supabase
        .from('other_reservations')
        .insert([dataToSubmit]);

      if (error) throw error;

      await fetchReservations();
      setIsModalOpen(false);
    } catch {
      setError('Virhe varauksen tallentamisessa');
    }
  };

  const handleDeleteClick = (reservation) => {
    setReservationToDelete(reservation);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const { error } = await supabase
        .from('other_reservations')
        .delete()
        .eq('id', reservationToDelete.id);

      if (error) throw error;

      await fetchReservations();
      setDeleteModalOpen(false);
      setReservationToDelete(null);
    } catch {
      setError('Virhe varauksen poistamisessa');
    }
  };

  // Lajittelufunktio
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  // Lajittelun nuoli-ikoni
  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) {
      return <span className="ml-1 text-gray-500">↕</span>;
    }
    return <span className="ml-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>;
  };

  // Lisätään PropTypes SortIcon-komponentille
  SortIcon.propTypes = {
    column: PropTypes.string.isRequired
  };

  // Suodatetaan ensin
  const filteredReservations = otherReservations.filter(reservation => {
    const searchLower = searchTerm.toLowerCase();
    return (
      reservation.name?.toLowerCase().includes(searchLower) ||
      reservation.company?.toLowerCase().includes(searchLower) ||
      reservation.email?.toLowerCase().includes(searchLower) ||
      reservation.section?.toLowerCase().includes(searchLower)
    );
  });

  // Sitten lajitellaan
  const sortedReservations = [...filteredReservations].sort((a, b) => {
    if (a[sortConfig.key] === null) return 1;
    if (b[sortConfig.key] === null) return -1;
    
    let comparison = 0;
    if (a[sortConfig.key]?.toLowerCase() < b[sortConfig.key]?.toLowerCase()) {
      comparison = -1;
    }
    if (a[sortConfig.key]?.toLowerCase() > b[sortConfig.key]?.toLowerCase()) {
      comparison = 1;
    }
    return sortConfig.direction === 'desc' ? comparison * -1 : comparison;
  });

  // Lisätään handleEdit funktio
  const handleEdit = async (formData) => {
    try {
      const formattedSection = formData.section === 'A' ? 'A-katsomo' : 'D-katsomo';
      
      const updateData = {
        name: formData.name,
        company: formData.company,
        email: formData.email,
        mobile: formData.mobile,
        other_info: formData.other_info,
        section: formattedSection,
        season: formData.season,
        reservation_type: formData.reservation_type
      };

      const { error } = await supabase
        .from('other_reservations')
        .update(updateData)
        .eq('id', editingReservation.id);

      if (error) throw error;

      setEditingReservation(null);
      await fetchReservations();
      
    } catch (error) {
      setError('Virhe varauksen päivittämisessä: ' + error.message);
    }
  };

  const fetchAllReservations = async () => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('other_reservations')
        .select('*');

      if (error) throw error;

      setOtherReservations(data || []);
     // console.log('Haetut varaukset:', data); // Debug-loki
    } catch (error) {
      console.error('Virhe varausten haussa:', error);
      setError('Virhe varausten hakemisessa');
    } finally {
      setLoading(false);
    }
  };

  // Lisätään useEffect fetchAllReservations-funktion jälkeen
  useEffect(() => {
    fetchAllReservations();
  }, []);

  const handleMassReservation = async (formData) => {
    try {
      const reservations = [];
      const { quantity, section, ...baseData } = formData;
      
      // Muunnetaan katsomon nimi oikeaan muotoon
      const formattedSection = section === 'A' ? 'A-katsomo' : 'D-katsomo';
      
      // Luodaan määrän mukainen määrä identtisiä varauksia
      for (let i = 0; i < quantity; i++) {
        reservations.push({
          ...baseData,
          section: formattedSection  // Käytetään muunnettua katsomon nimeä
        });
      }

      // Tallennetaan kaikki varaukset
      const { error } = await supabase
        .from('other_reservations')
        .insert(reservations);

      if (error) throw error;

      await fetchReservations();
      setMassReservationModalOpen(false);
      
    } catch (error) {
      console.error('Error:', error);
      setError('Virhe massavarausten tallentamisessa: ' + error.message);
    }
  };

  const exportToCSV = () => {
    const headers = [
      'Katsomo',
      'Nimi',
      'Puhelin',
      'Sähköposti',
      'Tyyppi',
      'Yritys',
      'Kausi',
      'Lisätiedot'
    ];
    
    const csvData = sortedReservations.map(reservation => [
      reservation.section || '',
      reservation.name || '',
      reservation.mobile || '',
      reservation.email || '',
      getReservationTypeInFinnish(reservation.reservation_type) || '',
      reservation.company || '',
      reservation.season || '',
      reservation.other_info || ''
    ]);
    
    // Yhdistä otsikot ja data
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');
    
    // Luo ja lataa tiedosto
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const timestamp = format(new Date(), 'yyyy-MM-dd_HHmm');
    
    link.setAttribute('href', url);
    link.setAttribute('download', `a_d_katsomot_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchActiveSeason = async () => {
    try {
      const { data, error } = await supabase
        .from('seasons')
        .select('*')
        .eq('is_active', true)
        .single();

      if (error) throw error;
      setActiveSeason(data);
    } catch (error) {
      console.error('Virhe kauden haussa:', error);
    }
  };

  const columns = [
    { key: 'actions', label: 'Toiminnot', width: 'w-20' },
    { key: 'section', label: 'Katsomo', width: 'w-24' },
    { key: 'name', label: 'Nimi', width: 'w-32' },          // Pienennetty leveys
    { key: 'mobile', label: 'Puhelin', width: 'w-32' },     // Siirretty tähän
    { key: 'email', label: 'Sähköposti', width: 'w-48' },   // Siirretty tähän
    { key: 'reservation_type', label: 'Tyyppi', width: 'w-40' },
    { key: 'company', label: 'Yritys', width: 'w-40' },
    { key: 'season', label: 'Kausi', width: 'w-32' },
    { key: 'other_info', label: 'Lisätiedot', width: 'w-48' }
  ];

  if (loading) return <div className="text-white text-center p-4">Ladataan...</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">A- ja D-katsomot</h1>
        <div className="flex items-center gap-4">
          {/* Hakukenttä */}
          <div className="relative">
            <input
              type="text"
              placeholder="Hae..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="p-2 pr-10 bg-gray-700 text-white rounded border border-gray-600 
                focus:border-blue-500 focus:outline-none w-48"
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 
                  text-gray-400 hover:text-white transition-colors"
                aria-label="Tyhjennä haku"
              >
                <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </button>
            )}
          </div>

          {/* Lisää varaus -painike */}
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md 
              transition-colors duration-150 ease-in-out flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
            </svg>
            <span className="hidden sm:inline">Lisää varaus</span>
          </button>

          {/* Massavaraus-painike */}
          <button
            onClick={() => setMassReservationModalOpen(true)}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md 
              transition-colors duration-150 ease-in-out flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5z" />
              <path d="M11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
            </svg>
            <span className="hidden sm:inline">Massavaraus</span>
          </button>

          {/* CSV-vienti -painike */}
          <button
            onClick={exportToCSV}
            className="bg-yellow-600 hover:bg-yellow-700 text-white px-4 py-2 rounded-md 
              transition-colors duration-150 ease-in-out flex items-center gap-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
            <span className="hidden sm:inline">Vie CSV</span>
          </button>
        </div>
      </div>

      <div className="overflow-hidden rounded-lg border border-gray-700">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-700">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  onClick={() => column.key !== 'actions' && handleSort(column.key)}
                  className={`px-3 py-2 text-left text-xs font-medium text-gray-300 uppercase tracking-wider md:px-6 cursor-pointer hover:bg-gray-700
                    ${column.responsive ? `hidden ${column.responsive}:table-cell` : ''}`}
                  >
                    <div className="flex items-center gap-2">
                      {column.label}
                      {column.key !== 'actions' && <SortIcon column={column.key} />}
                    </div>
                  </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-gray-900 divide-y divide-gray-700">
            {sortedReservations.map((reservation) => (
              <tr key={reservation.id}>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  <div className="flex gap-2">
                    <button
                      onClick={() => setEditingReservation(reservation)}
                      className="p-2 text-blue-500 hover:text-blue-400 transition-colors duration-150 rounded-full hover:bg-blue-500/10"
                      title="Muokkaa varausta"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    <button
                      onClick={() => handleDeleteClick(reservation)}
                      className="p-2 text-red-500 hover:text-red-400 transition-colors duration-150 rounded-full hover:bg-red-500/10"
                      title="Poista varaus"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.section}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.name}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.mobile}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.email}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {getReservationTypeInFinnish(reservation.reservation_type)}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.company}
                </td>
                <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-300 md:px-6">
                  {reservation.season}
                </td>
                <td className="px-3 py-2 text-sm text-gray-300 md:px-6">
                  <div className="max-w-xs overflow-hidden text-ellipsis">
                    {reservation.other_info}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <OtherReservationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
          activeSeason={activeSeason?.name}
        />
      )}

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setReservationToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
      />

      {editingReservation && (
        <OtherReservationModal
          isOpen={!!editingReservation}
          onClose={() => setEditingReservation(null)}
          onSubmit={handleEdit}
          existingData={{
            ...editingReservation,
            section: editingReservation.section === 'A-katsomo' ? 'A' : 'D'
          }}
          onDelete={() => handleDeleteClick(editingReservation)}
        />
      )}

      {massReservationModalOpen && (
        <MassReservationModal
          isOpen={massReservationModalOpen}
          onClose={() => setMassReservationModalOpen(false)}
          onSubmit={handleMassReservation}
        />
      )}
    </div>
  );
};

export default OtherReservationList; 