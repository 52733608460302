import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { supabase } from '../lib/supabaseClient';
import ReservationModal from './ReservationModal';

const SeatMap = ({ onSeatClick, reservations = {} }) => {
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [activeSeason, setActiveSeason] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const disabledSeats = [
    'B1-12', 'B1-13', 'B1-14', 'B1-15', 'B1-16',
    'B2-12', 'B2-13', 'B2-14', 'B2-15', 'B2-16',
    'B3-12', 'B3-13', 'B3-14', 'B3-15', 'B3-16',
    'C1-1', 'C1-2', 'C1-3', 'C1-4',
    'C2-1', 'C2-2', 'C2-3', 'C2-4',
    'C3-1', 'C3-2', 'C3-3', 'C3-4',
    'B8-7', 'B8-15', 'B8-16', 'B7-16', 'C8-1', 'C8-9', 'C7-1'
  ];
  
  const createSeatButton = (seatId) => {
    const reservation = reservations[seatId];
    const isDisabled = disabledSeats.includes(seatId);

    if (isDisabled) {
      return (
        <div key={seatId} className="relative group">
          <button
            className={`w-8 h-6 sm:w-12 sm:h-8 text-[8px] sm:text-[10px] rounded 
              bg-gray-800 text-gray-800 cursor-default`}
            disabled={true}
          >
            {seatId}
          </button>
        </div>
      );
    }

    return (
      <div key={seatId} className="relative group">
        <button
          onMouseEnter={() => {
            if (reservation) {
              setTooltipInfo({
                seatId,
                name: reservation.name,
                company: reservation.company
              });
            }
          }}
          onMouseLeave={() => setTooltipInfo(null)}
          className={`w-8 h-6 sm:w-12 sm:h-8 text-[8px] sm:text-[10px] rounded 
            ${getSeatColor(reservation)} text-white hover:opacity-80 
            transition-opacity whitespace-nowrap overflow-visible`}
          onClick={() => onSeatClick(seatId)}
        >
          {seatId}
        </button>
        
        {tooltipInfo && tooltipInfo.seatId === seatId && (
          <div className="fixed ml-6 mt-2 
            bg-gray-900 text-white px-3 py-2 rounded-lg 
            shadow-2xl border border-gray-700 
            text-sm whitespace-nowrap z-[9999] pointer-events-none
            backdrop-blur-sm bg-opacity-95">
            <div className="font-bold">{tooltipInfo.name}</div>
            {tooltipInfo.company && (
              <div className="text-gray-300">{tooltipInfo.company}</div>
            )}
          </div>
        )}
      </div>
    );
  };

  const getSeatColor = (reservation) => {
    if (!reservation) return 'bg-green-500';
    switch (reservation.reservation_type) {
      case 'reserved': return 'bg-red-500';
      case 'partner': return 'bg-blue-500';
      case 'guest': return 'bg-yellow-500';
      case 'other': return 'bg-gray-500';
      default: return 'bg-green-500';
    }
  };

  const createBSection = () => {
    const rows = Array.from({ length: 8 }, (_, i) => i + 1);
    const seats = Array.from({ length: 10 }, (_, i) => i + 7);
    
    return rows.map(row => (
      <div key={`B${row}`} className="flex gap-1">
        {seats.map(seatNum => {
          const seatId = `B${row}-${seatNum}`;
          return createSeatButton(seatId);
        })}
      </div>
    ));
  };

  const createCSection = () => {
    const rows = Array.from({ length: 8 }, (_, i) => i + 1);
    const seats = Array.from({ length: 9 }, (_, i) => i + 1);
    
    return rows.map(row => (
      <div key={`C${row}`} className="flex gap-1">
        {seats.map(seatNum => {
          const seatId = `C${row}-${seatNum}`;
          return createSeatButton(seatId);
        })}
      </div>
    ));
  };

  const Legend = () => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-center gap-3 sm:gap-6 p-4 bg-gray-800 rounded-lg border border-gray-700 w-full sm:w-auto">
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-green-500 rounded"></div>
        <span className="text-gray-200 text-sm">Vapaa paikka</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-red-500 rounded"></div>
        <span className="text-gray-200 text-sm">Varattu paikka</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-blue-500 rounded"></div>
        <span className="text-gray-200 text-sm">Kumppani</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-yellow-500 rounded"></div>
        <span className="text-gray-200 text-sm">Kausikortti Lippu.fi</span>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-4 h-4 bg-gray-500 rounded"></div>
        <span className="text-gray-200 text-sm">Muu</span>
      </div>
    </div>
  );

  useEffect(() => {
    const fetchActiveSeason = async () => {
      try {
        const { data, error } = await supabase
          .from('seasons')
          .select('id, name, start_date, end_date')
          .eq('is_active', true)
          .single();

        if (error) throw error;
        setActiveSeason(data);
      } catch (err) {
        console.error('Virhe aktiivisen kauden haussa:', err);
      }
    };

    fetchActiveSeason();
  }, []);

  const handleReservationSubmit = async (formData) => {
    if (!activeSeason) {
      console.error('Ei aktiivista kautta!');
      return;
    }

    try {
      const reservationData = {
        ...formData,
        season_id: activeSeason.id
      };

      console.log('Tallennettava data:', reservationData);

      const { error } = await supabase
        .from('reservations')
        .insert([reservationData])
        .select();

      if (error) {
        throw error;
      }

      setIsModalOpen(false);

    } catch (err) {
      console.error('Varauksen tallennus epäonnistui:', err);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {activeSeason && (
        <div className="mb-8 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 rounded-lg p-4 shadow-lg border border-gray-600">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0">
                <div className="h-3 w-3 rounded-full bg-green-500 animate-pulse"></div>
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">
                  {activeSeason.name}
                </h2>
                <p className="text-sm text-gray-400">
                  Aktiivinen varauskausi
                </p>
              </div>
            </div>
            <div className="hidden md:block px-4 py-2 bg-gray-900/50 rounded-lg">
              <p className="text-sm text-gray-300">
                {activeSeason.start_date && activeSeason.end_date ? (
                  `${new Date(activeSeason.start_date).toLocaleDateString('fi-FI')} - ${new Date(activeSeason.end_date).toLocaleDateString('fi-FI')}`
                ) : (
                  'Päivämäärät puuttuvat'
                )}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex flex-col items-center gap-4 sm:gap-8 p-4 sm:p-8 w-full">
          <div className="w-full overflow-x-auto min-w-full">
            <div className="flex flex-col sm:flex-row justify-center gap-8 sm:gap-16 min-w-max px-4">
              <div className="flex flex-col gap-0.5 sm:gap-1">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  B-katsomo
                </div>
                {createBSection()}
              </div>

              <div className="flex flex-col gap-0.5 sm:gap-1">
                <div className="text-gray-300 text-center text-sm sm:text-base mb-1 sm:mb-2">
                  C-katsomo
                </div>
                {createCSection()}
              </div>
            </div>
          </div>

          <Legend />
        </div>
      </div>

      {isModalOpen && (
        <ReservationModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleReservationSubmit}
        />
      )}
    </div>
  );
};

SeatMap.propTypes = {
  onSeatClick: PropTypes.func.isRequired,
  reservations: PropTypes.object.isRequired
};

export default SeatMap;